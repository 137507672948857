/* ConfirmationModal.css */
body {
    font-family: 'Varela Round', sans-serif;
  }
  
  .modal-confirm {
    color: #636363;
    width: -webkit-fill-available;
    font-size: 14px;
    margin-bottom: 5rem;
    padding-bottom: 5rem;

  }
  .mp {
    color: var(--mp-blue);
    border-color: var(--mp-blue);
  }
  .mp:hover {
    background-color: var(--mp-blue);
  }
  .mp a {
    color: var(--mp-blue);
  }
  
  .mp:hover #mp-logo {
    filter: invert(0%) sepia(100%) saturate(7456%) hue-rotate(115deg) brightness(108%) contrast(109%);
  }
  #mp-logo {
    transition: 0.1s;
    margin-left: 0.2rem;
    filter: invert(45%) sepia(22%) saturate(3891%) hue-rotate(168deg) brightness(97%) contrast(101%);
    height: 1.9rem;
  }

  .modal-content{
    height: auto;
  }
  
  .modal-confirm .modal-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
    display: contents !important;
  }
  
 .modal-header {
    border-bottom: none;
    position: relative;
    margin-top: 1rem;
    justify-content: center;
  }
  
  .modal-title{
    text-align: center;
    font-size: 26px;
    margin: 30px 0 -15px;
  }
  
.icon-box {
    color: #fff;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -70px;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    z-index: 9;
    background: #82ce34;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }
  
.icon-box i {
    font-size: 58px;
    position: relative;
    top: 3px;
  }
  
  .modal-confirm .modal-dialog {
    margin-top: 80px;
  }
  
  .modal-confirm .btn {
    color: #fff;
    border-radius: 4px;
    background: #82ce34;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border: none;
  }
  
  .modal-confirm .btn:hover,
  .modal-confirm .btn:focus {
    background: #6fb32b;
    outline: none;
  }
  
  .trigger-btn {
    display: inline-block;
    margin: 100px auto;
  }

  .modal-footer{
    margin-bottom: 0;
    position: inherit;
  }
  