.container_row{
    display: grid;
  }
  
  .layer1, .layer2{
    grid-column: 1;
    grid-row: 1;
  }
  
  .layer2{
    z-index: 9;
    height: auto;
    display: table;
  }
  .layer2 h1{
    color: white;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    display: table-cell;
  }

  .fixCol{
    padding: 1rem 2rem 1rem 2rem;    
  }