.loading-overlay {
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    height: 100vh;
    background-color: rgba(var(--secondaryColor), 0.85);
}