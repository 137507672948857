.eventCard {
	cursor: pointer;
  border-radius: 0;
}

.eventCard:hover{
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    border: 1px solid var(--primaryColor);
    background-color: white;
  }

  .eventCard .zoom-wrapper {
	overflow: hidden;
	margin:1rem;
  }

  .eventCard .zoom-img {
	transition: transform 0.3s ease-in-out;
  }
  
  .eventCard .zoom-wrapper:hover .zoom-img {
	transform: scale(1.2);
  }