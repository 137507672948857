.Disponible,.Reservado,.Vendido{
    padding: 5px;
    margin: 5px;
    text-align: center;
    text-justify:auto;
    height: 70px;
    width: 150px;
}

.Disponible{
    background-color: rgb(52, 129, 52);
    color: white;
   
}

.Disponible:hover{
    background-color: rgb(157, 235, 181);
    cursor: pointer;
}

.Vendido{
    background-color: rgb(155, 40, 40);
    color: white;

}

.Reservado{
    background-color: rgb(255, 208, 0);
    color: black;

}