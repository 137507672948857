.pitch2h{
    background-color: #238729;
    height: 36.875rem;
    width: 23.75rem;
    position: relative;
}
.pitch2h:after{
  content: "";
  border-top: 0.063rem solid white;
  position: absolute;
  right :1.25rem;
  top :18.438rem;
  display:block;
  width:21.25rem;
}

.pitch2h:before{
  content: "";
  border: 0.063rem solid white;
  position: absolute;
  right :1.25rem;
  top :1.25rem;
  display:block;
  height: 34.375rem;
  width:21.25rem;
}

.ingoalh{
    position: absolute;
    width: 21.25rem;
    height: 3.75rem;
    right: 1.25rem;
    border: 0.063rem solid white;
    border-right: none;
    border-left: none;
}

/* .echh{
      
   
} */

.echToph{    
    right: 10rem;    
    position: absolute;  
    width: 2.5rem;    
    height: 2.5rem;
    border: 0.125rem solid white;
    
}
.echBottomh{
    right: 10rem;    
    position: absolute;  
    width: 2.5rem;
    height: 0.313rem;
    border: 0.125rem solid white;    
}

.left2h .echh .echToph{
    top:1.875rem;
    border-top: none;
    border-bottom: none;
}
.left2h .echh .echBottomh{
    border-bottom: none;
    top:3.75rem;
}

.right2h .echh .echToph{
    bottom:1.875rem;
    border-top: none;
    border-bottom: none;
}
.right2h .echh .echBottomh{
    border-top: none;
    bottom:3.75rem;
}
.twentytwoh{
    position: absolute;
    width: 21.25rem;
    height: 3.75rem;
    right: 1.25rem;
    border: 0.063rem solid white;
    border-right: none;
    border-left: none;
}

.tensh{
    position: absolute;
    width: 21.25rem;
    height: 3.75rem;
    right: 1.25rem;
    border: 0.063rem dashed white;
    border-right: none;
    border-left: none;
}

.left2h .ingoalh{
    top: 0.625rem;
    border-top: 0;    
}

.left2h .twentytwoh{
    top: 5rem;
    border-top: 0;
    margin-top: 0.313rem;
}

.left2h .tensh{
    top: 10rem;
    border-top: 0;
    margin-top: 0.313rem;
}

.right2h .ingoalh{
    bottom: 0.625rem;
    border-bottom: 0;    
}

.right2h .twentytwoh{
    bottom: 5rem;
    border-bottom: 0;
    margin-bottom: 0.313rem;
}

.right2h .tensh{
    bottom: 10rem;
    border-bottom: 0;
    margin-bottom: 0.313rem;
}

.fivemetersh{
    position: absolute;
    width: 0.625rem;
    top: 100px;
    height: 390px;
    border: 0.063rem dashed white;
    border-bottom: none;
    border-top: none;    
}

.fifteenmetersh{
    position: absolute;
    width: 50px;
    top: 100px;
    height: 390px;
    border: 0.063rem dashed white;
    border-bottom: none;
    border-top: none;    
}

.toph .fivemetersh{
    right: 1.875rem;
    border-right: none;
}

.bottomh .fivemetersh{
    left: 1.875rem;
    border-left: none;
}

.toph .fifteenmetersh{
    right: 1.875rem;
    border-right: none;
}

.bottomh .fifteenmetersh{
    left: 1.875rem;
    border-left: none;
}