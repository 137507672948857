.pitch2{
    background-color: #238729;
    width: 36.875rem;
    height: 23.75rem;
    position: relative;
}
.pitch2:after{
  content: "";
  border-left: 0.063rem solid white;
  position: absolute;
  top :1.25rem;
  left :18.438rem;
  display:block;
  height:21.25rem;
}

.pitch2:before{
  content: "";
  border: 0.063rem solid white;
  position: absolute;
  top :1.25rem;
  left :1.25rem;
  display:block;
  width: 34.375rem;
  height:21.25rem;
}

.ingoal{
    position: absolute;
    height: 21.25rem;
    width: 3.75rem;
    top: 1.25rem;
    border: 0.063rem solid white;
    border-top: none;
    border-bottom: none;
}

/* .ech{
      
   
} */

.echTop{    
    top: 10rem;    
    position: absolute;  
    height: 2.5rem;    
    width: 2.5rem;
    border: 0.125rem solid white;
    
}
.echBottom{
    top: 10rem;    
    position: absolute;  
    height: 2.5rem;
    width: 0.313rem;
    border: 0.125rem solid white;    
}

.left2 .ech .echTop{
    left:1.875rem;
    border-left: none;
    border-right: none;
}
.left2 .ech .echBottom{
    border-right: none;
    left:3.75rem;
}

.right2 .ech .echTop{
    right:1.875rem;
    border-left: none;
    border-right: none;
}
.right2 .ech .echBottom{
    border-left: none;
    right:3.75rem;
}
.twentytwo{
    position: absolute;
    height: 21.25rem;
    width: 3.75rem;
    top: 1.25rem;
    border: 0.063rem solid white;
    border-top: none;
    border-bottom: none;
}

.tens{
    position: absolute;
    height: 21.25rem;
    width: 3.75rem;
    top: 1.25rem;
    border: 0.063rem dashed white;
    border-top: none;
    border-bottom: none;
}

.left2 .ingoal{
    left: 0.625rem;
    border-left: 0;    
}

.left2 .twentytwo{
    left: 5rem;
    border-left: 0;
    margin-left: 0.313rem;
}

.left2 .tens{
    left: 10rem;
    border-left: 0;
    margin-left: 0.313rem;
}

.right2 .ingoal{
    right: 0.625rem;
    border-right: 0;    
}

.right2 .twentytwo{
    right: 5rem;
    border-right: 0;
    margin-right: 0.313rem;
}

.right2 .tens{
    right: 10rem;
    border-right: 0;
    margin-right: 0.313rem;
}

.fivemeters{
    position: absolute;
    height: 0.625rem;
    left: 100px;
    width: 390px;
    border: 0.063rem dashed white;
    border-right: none;
    border-left: none;    
}

.fifteenmeters{
    position: absolute;
    height: 50px;
    left: 100px;
    width: 390px;
    border: 0.063rem dashed white;
    border-right: none;
    border-left: none;    
}

.top .fivemeters{
    top: 1.875rem;
    border-top: none;
}

.bottom .fivemeters{
    bottom: 1.875rem;
    border-bottom: none;
}

.top .fifteenmeters{
    top: 1.875rem;
    border-top: none;
}

.bottom .fifteenmeters{
    bottom: 1.875rem;
    border-bottom: none;
}


/* IN PX */
/* .pitch2{
    background-color: #238729;
    width: 590px;
    height: 380px;
    position: relative;
}
.pitch2:after{
  content: "";
  border-left: 1px solid white;
  position: absolute;
  top :20px;
  left :295px;
  display:block;
  height:340px;
}

.pitch2:before{
  content: "";
  border: 1px solid white;
  position: absolute;
  top :20px;
  left :20px;
  display:block;
  width: 550px;
  height:340px;
}

.ingoal{
    position: absolute;
    height: 340px;
    width: 60px;
    top: 20px;
    border: 1px solid white;
    border-top: none;
    border-bottom: none;
}

.echTop{    
    top: 160px;    
    position: absolute;  
    height: 40px;    
    width: 40px;
    border: 2px solid white;
    
}
.echBottom{
    top: 160px;    
    position: absolute;  
    height: 40px;
    width: 5px;
    border: 2px solid white;    
}

.left2 .ech .echTop{
    left:30px;
    border-left: none;
    border-right: none;
}
.left2 .ech .echBottom{
    border-right: none;
    left:60px;
}

.right2 .ech .echTop{
    right:30px;
    border-left: none;
    border-right: none;
}
.right2 .ech .echBottom{
    border-left: none;
    right:60px;
}
.twentytwo{
    position: absolute;
    height: 340px;
    width: 60px;
    top: 20px;
    border: 1px solid white;
    border-top: none;
    border-bottom: none;
}

.tens{
    position: absolute;
    height: 340px;
    width: 60px;
    top: 20px;
    border: 1px dashed white;
    border-top: none;
    border-bottom: none;
}

.left2 .ingoal{
    left: 10px;
    border-left: 0;    
}

.left2 .twentytwo{
    left: 80px;
    border-left: 0;
    margin-left: 5px;
}

.left2 .tens{
    left: 160px;
    border-left: 0;
    margin-left: 5px;
}

.right2 .ingoal{
    right: 10px;
    border-right: 0;    
}

.right2 .twentytwo{
    right: 80px;
    border-right: 0;
    margin-right: 5px;
}

.right2 .tens{
    right: 160px;
    border-right: 0;
    margin-right: 5px;
}

.fivemeters{
    position: absolute;
    height: 10px;
    left: 100px;
    width: 390px;
    border: 1px dashed white;
    border-right: none;
    border-left: none;    
}

.fifteenmeters{
    position: absolute;
    height: 50px;
    left: 100px;
    width: 390px;
    border: 1px dashed white;
    border-right: none;
    border-left: none;    
}

.top .fivemeters{
    top: 30px;
    border-top: none;
}

.bottom .fivemeters{
    bottom: 30px;
    border-bottom: none;
}

.top .fifteenmeters{
    top: 30px;
    border-top: none;
}

.bottom .fifteenmeters{
    bottom: 30px;
    border-bottom: none;
} */