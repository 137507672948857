.pitchh{
    background-color: #238729;
    height: 36.875rem;
    width: 23.75rem;
    position: relative;
}
.pitchh:after{
  content: "";
  border-top: 0.063rem solid white;
  position: absolute;
  right :1.25rem;
  top :18.438rem;
  display:block;
  width:21.25rem;
}

.pitchh:before{
  content: "";
  border: 0.063rem solid white;
  position: absolute;
  right :1.25rem;
  top :1.25rem;
  display:block;
  height: 34.375rem;
  width:21.25rem;
}

.center-circleh{
    position: absolute;
    right: 9.063rem;
    top: 15.625rem;
    height: 5.625rem;
    width: 5.625rem;
    border: 0.063rem solid white;
    border-radius: 50%;
}
.center-circleh:after{
    position: absolute;
    right: 2.688rem;
    top: 2.688rem;
    height: 0.063rem;
    width: 0.063rem;
    background-color:white;
    content:"";
    border:0.063rem solid white;
}

.penalty-areah{
    position: absolute;
    width: 8.75rem;
    height: 4rem;
    right: 7.5rem;
    border: 0.063rem solid white;
}

.lefth .penalty-areah{
    top: 1.25rem;
    border-top: 0;
}

.righth .penalty-areah{
    bottom: 1.188rem;
    border-bottom: 0;
}

.penalty-areah:before{
    position: absolute;
    width: 4.375rem;
    height: 2.188rem;
    right: 2.188rem;
    border: 0.063rem solid white;
    content:"";
}
.lefth .penalty-areah:before{
   top:0rem;
  border-top:0;
}
.righth .penalty-areah:before{
   bottom:0rem;
  border-bottom:0;
}

.penalty-areah:after{
    position: absolute;
    width: 0.125rem;
    height: 0.125rem;
    right: 4.375rem;
    background-color:white;
    content:"";
}
.lefth .penalty-areah:after{
    bottom:0.938rem;
}
.righth .penalty-areah:after{
    top:0.938rem;
}
