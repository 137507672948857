.pitch{
    background-color: #238729;
    width: 36.875rem;
    height: 23.75rem;
    position: relative;
}
.pitch:after{
  content: "";
  border-left: 0.063rem solid white;
  position: absolute;
  top :1.25rem;
  left :18.438rem;
  display:block;
  height:21.25rem;
}

.pitch:before{
  content: "";
  border: 0.063rem solid white;
  position: absolute;
  top :1.25rem;
  left :1.25rem;
  display:block;
  width: 34.375rem;
  height:21.25rem;
}

.center-circle{
    position: absolute;
    top: 9.063rem;
    left: 15.625rem;
    width: 5.625rem;
    height: 5.625rem;
    border: 0.063rem solid white;
    border-radius: 50%;
}
.center-circle:after{
    position: absolute;
    top: 2.688rem;
    left: 2.688rem;
    width: 0.063rem;
    height: 0.063rem;
    background-color:white;
    content:"";
    border:0.063rem solid white;
}

.penalty-area{
    position: absolute;
    height: 8.75rem;
    width: 4rem;
    top: 7.5rem;
    border: 0.063rem solid white;
}

.left .penalty-area{
    left: 1.25rem;
    border-left: 0;
}

.right .penalty-area{
    right: 1.188rem;
    border-right: 0;
}

.penalty-area:before{
    position: absolute;
    height: 4.375rem;
    width: 2.188rem;
    top: 2.188rem;
    border: 0.063rem solid white;
    content:"";
}
.left .penalty-area:before{
   left:0rem;
  border-left:0;
}
.right .penalty-area:before{
   right:0rem;
  border-right:0;
}

.penalty-area:after{
    position: absolute;
    height: 0.125rem;
    width: 0.125rem;
    top: 4.375rem;
    background-color:white;
    content:"";
}
.left .penalty-area:after{
    right:0.938rem;
}
.right .penalty-area:after{
    left:0.938rem;
}




/* PX FIELD */
/* .pitch{
    background-color: #238729;
    width: 590px;
    height: 380px;
    position: relative;
}
.pitch:after{
  content: "";
  border-left: 1px solid white;
  position: absolute;
  top :20px;
  left :295px;
  display:block;
  height:340px;
}

.pitch:before{
  content: "";
  border: 1px solid white;
  position: absolute;
  top :20px;
  left :20px;
  display:block;
  width: 550px;
  height:340px;
}

.center-circle{
    position: absolute;
    top: 145px;
    left: 250px;
    width: 90px;
    height: 90px;
    border: 1px solid white;
    border-radius: 50%;
}
.center-circle:after{
    position: absolute;
    top: 43px;
    left: 43px;
    width: 1px;
    height: 1px;
    background-color:white;
    content:"";
    border:1px solid white;
}

.penalty-area{
    position: absolute;
    height: 140px;
    width: 80px;
    top: 120px;
    border: 1px solid white;
}

.left .penalty-area{
    left: 20px;
    border-left: 0;
}

.right .penalty-area{
    right: 19px;
    border-right: 0;
}

.penalty-area:before{
    position: absolute;
    height: 70px;
    width: 35px;
    top: 35px;
    border: 1px solid white;
    content:"";
}
.left .penalty-area:before{
   left:0px;
  border-left:0;
}
.right .penalty-area:before{
   right:0px;
  border-right:0;
}

.penalty-area:after{
    position: absolute;
    height: 2px;
    width: 2px;
    top: 70px;
    background-color:white;
    content:"";
}
.left .penalty-area:after{
    right:15px;
}
.right .penalty-area:after{
    left:15px;
} */