.purchase-tickets-modal-ticket .modal-header-ticket {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #d3d3d3;
    padding-right: 1.2rem;
    margin: 0px !important
  }

  


  .modal-title-ticket{
    margin: 0px !important;
    font-size: 20px !important;
    font-weight: bold;
  }
  
  .purchase-tickets-modal-ticket .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  
  .modal-content-container-ticket {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .modal-top-ticket {
    display: flex;
    justify-content: space-evenly;
    width: 100% !important;
    margin-bottom: 20px;
  }
  
  .player-info-ticket {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .card-image-ticket {
    width: 50px; /* Adjust the width as needed */
    height: 75px; /* Adjust the height as needed */
    margin-bottom: 10px;
  }
  
  .cost-ticket {
    display: flex;
    justify-content: center;
    align-items: baseline;
    width: 15% !important;
    font-weight: bold;
    font-size: 1.0em;
    
  }

  .cost-box{
    background-color: #d9d9d9;
    color: black;
    padding: 3px 5px;
    border: 0.8px double black;
    font-family:serif
  }
  
  .form-group-ticket {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    margin-bottom: 10px;
  }
  
  .form-group-ticket label {
    margin-bottom: 5px;
  }
  
  .form-group-ticket input {
    width: 80%;
    padding: 5px;
    border: 1px solid black;
    border-radius: 4px;
  }
  
  .button-row-ticket {
    width: 100%;
  }
  .transferencia-container {
    text-align: left; /* Align text to the left */
    padding: 2px;  
    width: 100%; 
  }
  
  .transferencia-container strong {
    font-weight: bold;
  }
  
  .comprobante-upload {
    margin-top: 2px; /* Add space above the file input */
  }
  
  .comprobante-upload label {
    display: block;    /* Ensure the label is on its own line */
    margin-bottom: 5px;  /* Add space between label and input */
  }

  .transferencia-card{
width: 100%;
    background-color: #d3d3d3;
    padding: 1.5rem;
  }

  .modal-body-tickets{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mp-caption {
    padding: 0.5rem;
    margin-top: 0.5rem;
    font-size: 14px;
    border: 1px solid rgba(33, 37, 41, 0.331);
    border-radius: 8px;
  }

  .custom-spinner-mp{
    width: 1rem;
    height: 1rem;
    border-width: 0.15em;
    color: #009ee3;
  }

  .player-info-ticket .input-player {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box; /* Ensures padding and border are included in the width */
  }

  .error-message{
    color: #a94442;
  }