.admin-footer p {
  margin: 0rem auto !important;
  color: black;
  font-size: 12px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;

}

.gotaFooter {
  font-weight: 500 !important;
  background-color: var(--primaryColor) !important;
}
.gotaFooter img{
  float:none;
  width: 100px;
  margin: 5px;
  margin-bottom: 0;
}
.gotaFooter a p{
  font-size: 15px;
  color: white;
}

.copyrightFooter {
  font-size: 8px;
}


@media screen and (max-width: 991px) {

.gotaFooter img{
  margin:auto
}
}