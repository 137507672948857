div.first {
    display: block;
    text-align: center;
    max-height: 16em;
    max-width: 8em;
    min-height: 7rem;
    min-width: 2rem;
    position: relative;
  }
  
  div.first:hover {
    background-color: var(--secondaryColor);
    cursor: pointer;
  }
  
  p.second {
    position: relative; 
    height: 100%;
    width: 100%;
  }
  p.second span{
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .second input[type='checkbox'] {
    transform: translateY(15%);
    cursor: pointer;
    position: absolute;
    right: 0.1rem;
    top: 0.1rem;
  }
  .second .iconTop{
  position: absolute;
    right: 0.1rem;
    top: 0.1rem;
  }
  input[type="checkbox"]:focus {
    outline: max(2px, 0.15em) solid currentColor;
    outline-offset: max(2px, 0.15em);
  }