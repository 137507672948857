.event-summary {
    height: 28rem;
    /* width: 80%; */
    position: relative;
    margin: auto;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
#event-screen-container {
    padding: 0;
    background-color: white;
    /* max-width:100% !important; */
    min-width: 100vw !important;
}
.event-summary-background {
    background : linear-gradient(
        to right,
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0.264),
        rgba(0, 0, 0, 0.264),
        rgba(0, 0, 0, 0.8)
      );
}

.event-summary img {
    height: 100%;
}
.event-summary h1,.event-summary h2  {
    margin-bottom: 0.2rem;
    color: white;
    font-weight: 900;
    text-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px;
}
.event-summary h1 {
    font-size: 2.2rem;
}
.event-tickets {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 2rem;
}
.tab-content {
    min-height: 600px;
}
.event-tickets .ticket-type{
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.157);
    padding : 0.7rem;
    display: flex;
    font-weight: 600;
    font-size: 1rem;
}
.event-tickets .ticket-type .name{
    width: 50%;
}
.event-tickets .ticket-type .numOfTickets{
   display: inline-block;
   margin-left: 0.5rem;
   font-weight: 300;
   font-size: 0.95rem;
}
.event-tickets .ticket-type .price{
    width: 15%;
    font-weight: 300;
    text-align: end;
}
.event-tickets .ticket-type .btn-container{
    width: 33.3%;
    text-align: end;
}
.event-tickets .ticket-type .btn-container button{
    border-radius: 0%;
    background-color: rgb(7, 7, 168) !important;
    font-size: 0.78rem;
}
.venue-img-container {
    height: 35rem
}
#event-tabs {
    margin: auto;
    width: 80%;
    padding:0 10rem;
}
.section-nav-container {
    box-shadow:  rgba(18, 18, 18, 0.18) 0px 3px 12px 0px;
    justify-content: center;
}
.section-nav {
    display: flex;
    flex-wrap: wrap;
    
    justify-content: start;
    width: 80%;
}
.event-summary h2 {
    text-align: start;
    padding-left: 0.2rem;
}
.event-image-overlay {
    display: flex;
    flex-wrap: wrap;
    padding: 0 5rem;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    gap: 0;
    text-align: center;
    box-shadow: inset 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.264);
}
.mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-attachment: fixed;
}