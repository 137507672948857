.float-label {
    font-weight: 400;
    font-size: 0.75rem ;
}
.text-floating-label-content {
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);;
}
.float-text {
    font-size: 0.9rem;
}
.pen-edit-method {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    color: rgba(0, 0, 0, 0.14);
    transition: 0.2s
}
.pen-edit-method :hover {
    color: black;
    transition: 0.2s;
}
